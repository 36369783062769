export default {
  address: "243新北市泰山區信華六街&信華三街口",
  googleSrc:
    "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1807.346360911593!2d121.440119!3d25.0445!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb575f9fad8e0edd4!2z5piO5pel5Z-OMuaOpeW-heS4reW_gw!5e0!3m2!1szh-TW!2stw!4v1611812905624!5m2!1szh-TW!2stw",
  googleLink: "https://goo.gl/maps/wzA8yWbP9v4FE4J29",
  phone: "02-2297-8899",
  fbLink:
    "https://www.facebook.com/%E6%98%8E%E6%97%A5%E5%9F%8E2-102181241308191/",
  fbMessage: "https://m.me/102181241308191/",
  caseName: "明日城2",
  indigatorLength: 10,

  houseInfos: [
    ["投資興建", "勝華建設有限公司"],
    ["設計規劃", "上圓聯合建築師事務所"],
    ["企劃銷售", "新智力廣告有限公司"],
    ["景觀設計", "上馥工程有限公司"],
    ["基地位置", "新北市泰山區信華段五小段14地號"],
    ["接待中心", "新北市泰山區信華六街&信華三街口"]
  ],

  gtmCode: ["TWNVTH5","WQKLTJ7"], // 可放置多個
  recaptcha_site_key_v2: "6Lep-78UAAAAAMaZLtddpvpixEb8cqu7v7758gLz",
  recaptcha_site_key: "6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s", // recaptcha v3
  recaptcha_user_token: "6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa",
  order: {
    title: "預約專線",
    subTitle: "｜預約賞屋表單｜"
  }
};
