<template>
  <div class="contact" id="contact">
    <Order />
  </div>
</template>
<style lang="scss" scoped>
  .contact {
    background-color: #183eb9;
  }
</style>

<script>
import Order from "@/components/Order.vue";
//import HouseInfo from "@/components/HouseInfo.vue";

export default {
  name: "contactSection",
  components: {
    Order
    //HouseInfo
  },

  methods: {}
};
</script>
